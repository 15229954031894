<template>
  <v-carousel
    cycle
    :interval="interval"
    :height="wdHeight"
    :hide-delimiters="hideDelimiters"
    :show-arrows="showArrows"
    show-arrows-on-hover
    hide-delimiter-background
  >
    <v-carousel-item
      v-for="(src, index) in items"
      :key="index"
    >
      <v-img
        height="100%"
        :src="src.cover"
      />
    </v-carousel-item>
  </v-carousel>
</template>

<script>
  import user from '@/api/co.user'

  export default {
    name: 'UserBanner',
    props: {
      item: {
        type: Object,
        default: () => {
          return {}
        }
      },
      height: {
        type: [Number, String],
        default: 300
      },
      points: {
        type: Object,
        default: () => {
          return {
            xs: 200,
            sm: 240,
            md: 300,
            lg: 360,
            xl: 360
          }
        }
      },
      cycle: {
        type: Boolean,
        default: true
      },
      interval: {
        type: Number,
        default: 5000
      }
    },
    data () {
      return {
        hideDelimiters: true,
        showArrows: false,
        items: [],
        info: {},
        btnSet: {
          text: '设置封面'
        }
      }
    },
    computed: {
      wdHeight () {
        const name = this.$vuetify.breakpoint.name
        const ht = this.points[name] || this.height
        return ht
      },
    },
    watch: {
      item: {
        handler (val) {
          let cover = val.cover || ''
          this.items = []
          // this.hideDelimiters = true
          // this.showArrows = false
          if (!cover) {
            const info = user.info.get()
            cover = info.backgroundImage
          }

          this.items.push({
            cover
          })
        },
        immediate: true
      }
    },
    created () {
    },
    methods: {
      onAction (item) {
        this.$emit('action', item)
      },
      reset (ev) {
        if (ev.cover) {
          this.items = []
          this.items.push({
            cover: ev.cover
          })
        }
      },
    }
  }
</script>
